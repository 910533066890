<template>
  <div>
    <b-card title="Applikation-Setup" class="text-center">
      Diese Anwendung durchläuft einen kurzen Installationsprozess. Sie werden
      automatisch weitergeleitet.
      <p class="font-size-lg mt-5">
        {{ apiStateMessage }}
        <i
          v-if="apiState !== null"
          class="ml-3"
          :class="apiConnectionIconClass"
        />
      </p>
      <b-button
        v-if="apiState !== null && !isConnectedToApi"
        @click="startApplicationSetup"
      >
        Erneut versuchen
      </b-button>
      <div class="w-100">
        <span class="font-size-lg">
          {{ loadingStateMessage }}
          <i
            v-if="loadingState === 'finished loading'"
            class="ml-3 fas fa-check text-success"
          />
        </span>
      </div>
      <div
        v-if="isConnectedToApi && loadingState === 'loading addresses'"
        class="mt-15 mb-10"
      >
        <div class="row">
          <div class="col-md-10 col-12 pt-3">
            <v-progress-linear
              stream
              color="bg-primary"
              class="mb-3"
              :value="loadingValue"
              :buffer-value="0"
            ></v-progress-linear>
          </div>
          <div class="col-md-2 col-12 text-md-right text-center">
            <span
              v-if="loadingState === 'loading addresses'"
              class="font-size-lg"
            >
              {{ loadedAddressesCount }} / {{ maxAddressesCount }}
            </span>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "../../core/services/api.service";

export default {
  name: "ApplicationSetup",
  data() {
    return {
      apiState: null,
      loadingState: "",
      errorState: null
    };
  },
  computed: {
    apiStateMessage() {
      if (this.apiState === null) {
        return "Verbindung zu DICLINA wird aufgebaut...";
      }

      switch (this.apiState.status) {
        case 200:
          return "Verbindung zu DICLINA ist aktiv.";
        case 401:
          return "Sie sind nicht angemeldet.";
        default:
          return "Verbindung zu DICLINA ist fehlerhaft.";
      }
    },
    isConnectedToApi() {
      if (this.apiState === null) {
        return false;
      }

      switch (this.apiState.status) {
        case 200:
          return true;
        default:
          return false;
      }
    },
    apiConnectionIconClass() {
      return {
        "fas fa-check text-success": this.isConnectedToApi,
        "fas fa-times text-danger": !this.isConnectedToApi
      };
    },
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    maxAddressesCount() {
      return this.$store.getters.maxAddressesCount;
    },
    loadingValue() {
      return (this.loadedAddressesCount / this.maxAddressesCount) * 100;
    },
    loadingStateMessage() {
      switch (this.loadingState) {
        case "loading addresses":
          return "Adressen werden aktualisiert ...";
        case "finished loading":
          return "Alles aktualisiert.";
        default:
          return "";
      }
    }
  },
  methods: {
    async startApplicationSetup() {
      this.apiState = await ApiService.get("application-state");

      if (this.isConnectedToApi) {
        this.loadingState = "loading addresses";
        await this.loadAddresses();
      }
    },
    async loadAddresses() {
      await this.$store.dispatch("updateAddresses", true);

      if (this.maxAddressesCount === 0) {
        this.loadingState = "finished loading";
      }
    }
  },
  watch: {
    loadedAddressesCount(value) {
      if (value > 0 && value === this.maxAddressesCount) {
        this.loadingState = "finished loading";
      }
    },
    loadingState(value) {
      if (value === "finished loading") {
        setTimeout(() => {
          this.$router.push({ name: "addresses" });
        }, 5000);
      }
    }
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Applikation-Setup", subTitle: "Vertriebssteuerungstool" }
    ]);

    await this.startApplicationSetup();
  }
};
</script>

<style lang="scss"></style>
